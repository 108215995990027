import React, { Component } from "react";
import "./NavMenu.css";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  render() {
    return (
      <header>
        <div>
          <p>Call for papers</p>
          <p>Show time!</p>
        </div>
        <img alt="logo" src="./logo.png" />
      </header>
    );
  }
}
