import React, { useState } from "react";
import "./MeetupItem.css";
import { useMediaQuery } from "@react-hook/media-query";

export interface IMeetupItem {
  title: string;
  description: string;
  url: string;
  person?: string;
  year?: number;
  photo?: string;
}

const MeetupItem = (props: IMeetupItem) => {
  const [isHovering, setIsHovering] = useState(false);
  const matches = useMediaQuery("(max-width: 900px)");
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const advancedView = (
    <div className="details">
      <div className="time">
        <img alt="year" src="time.png" />
        {props.year}
      </div>
      <h3>{props.title}</h3>
      {props.photo && (
        <div className="photoContainer">
          <img alt={props.person} src={props.photo} className="photo" />
          <h4>{props.person}</h4>
        </div>
      )}
      <p>{props.description}</p>
    </div>
  );

  return (
    <div
      key={props.title}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={() => window.open(props.url)}
      className={matches ? "" : isHovering ? "hovereditem" : "item"}
    >
      {isHovering && !matches && <>Watch it on YouTube!</>}

      {!isHovering && !matches && (
        <>
          {props.person && props.year ? (
            advancedView
          ) : (
            <>
              <h3>{props.title}</h3>
              <p>{props.description}</p>
            </>
          )}
        </>
      )}

      {matches && (
        <div className="item">
          {props.person && props.year ? (
            advancedView
          ) : (
            <>
              <h3>{props.title}</h3>
              <p>{props.description}</p>
            </>
          )}
          Click to watch it on Youtube
        </div>
      )}
    </div>
  );
};

export default MeetupItem;
