import { IMeetupItem } from "./components/MeetupItem";

export const pastMeetups: IMeetupItem[] = [
  {
    title: "TWF Becoming a digital nomad",
    description:
      "You will learn some of the biggest challenges related to remote work (and how to overcome them), most popular destinations for digital nomads, tricks for staying productive, and best online communities for like-minded people.",
    url: "https://www.youtube.com/watch?v=6pqEpF6499U",
    person: "Wojtek Zając",
    year: 2020,
    photo: "wojtek-zając.png",
  },
  {
    title: "TWF Yet another JS Framework... or not? Svelte!",
    description:
      "This is mainly an introduction to Svelte showcasing some of its main features as well as other different web tools that it tries to push forward. We are also going to check how it differs from other JS frameworks like React/Angular/Vue.",
    url: "https://www.youtube.com/watch?v=nT8QtDBIKZA",
    person: "Vitor Antonio",
    year: 2020,
    photo: "vitor-antonio.png",
  },
  {
    title: "RxJS in Angular: from beginner to advanced beginner",
    description:
      "After watching this session you will be able to explain what RxJS is, how to use Observables and Subjects and which operators are especially useful in daily work.",
    url: "https://www.youtube.com/watch?v=YesyFEyj3UE",
    person: "Evgenii Vilkov",
    year: 2021,
    photo: "evgenii-vilkov.png",
  },
  {
    title: "nanoFramework: Run your C# code in ESP32",
    description:
      "Have you ever heard about nanoFramework? If not, this presenation is for you! You will learn what nanoFramework is, how to create IoT “hello world” and how to make a connection with Azure IoT Hub - everything will come down to running your C# code in ESP32.",
    url: "https://www.youtube.com/watch?v=j9QDQB_sQ_c",
    person: "Bartłomiej Leja",
    year: 2021,
    photo: "bartlomiej-leja.png",
  },
  {
    title: "Driving a robot with a neural network - use case study",
    description:
      "This session will guide you through the idea of how to drive the robot using computer vision to make it understand visual input. Here you will learn about neural networks, segmentation tasks, transfer learning, and neural network optimizations.",
    url: "https://www.youtube.com/watch?v=PHBVuYxAxSE",
    person: "Paweł Grudzień",
    year: 2022,
    photo: "pawel-grudzien.png",
  },
  {
    title: "An introduction to code testing",
    description:
      "During this meetup, Piotr Zieliński will share how to write code to be testable, what are black box and white box tests, what is the difference between mocking (MOQ) and faking dependencies and what is arrange-act-assert.",
    url: "https://www.youtube.com/watch?v=uoOeNhfkvLo",
    person: "Piotr Zieliński",
    year: 2022,
    photo: "piotr-zielinski.png",
  },
  {
    title: "An introduction to quantum computing",
    description:
      "Would you like to learn the necessary concepts related to quantum computing (including: qubit, superposition, quantum gates, quantum entanglement, quantum annealing) and to know how you can create programs for quantum computers and their simulators?",
    url: "https://www.youtube.com/watch?v=IB8NYkaTZ8A",
    person: "Paweł Gora",
    year: 2022,
    photo: "pawel-gora.png",
  },
  {
    title: "Zero Framework Overhead with Solid.js",
    description:
      "You will be introduced to Solid.js, a new JavaScript Framework that boasts best in class performance metrics. If you want to know if we really need another framework and what Solid.js is best at, watch our meetup!",
    url: "https://www.youtube.com/watch?v=Bubv8wlLIxo",
    person: "Andreas Roth",
    year: 2022,
    photo: "andreas-roth.png",
  },
  {
    title: "How to save IT resources (No-Code / Low-Code DPs)",
    description:
      "You will learn all you need to know about No-Code / Low-Code Development Platforms and how you can utilize them to improve your everyday work. You don't want to miss this one!",
    url: "https://www.youtube.com/watch?v=pktd-4pnQL0",
    person: "Michał Guzowski",
    year: 2022,
    photo: "michał-guzowski.png"
  },
  {
    title: "Azure Synapse Analytics in a business scenario (EDA)",
    description:
      "Damian would like to introduce you to the Azure Synapse Analytics and its fantastic possibilities for performing EDA (exploratory data analysis). He will show you real data and a demo of EDA using Spark.",
    url: "https://www.youtube.com/watch?v=VPXN1q_H_uc",
    person: "Damian Widera",
    year: 2023,
    photo: "damian-widera.png"
  },
  {
    title: "Everything you don't need to know about Git",
    description:
      "In this talk Garance focuses on the less know parts of Git. The forgotten bits of trivia, the little-known, not-for-everyday commands. You won’t leave with much to apply directly in your terminal, but with plenty to talk about on your company’s messaging platform!",
    url: "https://www.youtube.com/watch?v=GxRzZz4sJSI",
    person: "Garance Vallat",
    year: 2023,
    photo: "garance-vallat.png"
  },
  {
    title: "Why a nerd like me bets on Web3?",
    description:
      "Konrad would like to show his story from Web3/blockchain sceptic to a... sceptical fan of it. Jump in to listen to his reasoning and learn what he found intriguing about this interesting idea.",
    url: "https://www.youtube.com/watch?v=kbHn8xp-yfM",
    person: "Konrad Kokosa",
    year: 2023,
    photo: "konrad-kokosa.png"
  },
  {
    title: "Learning AI through play and experimentation",
    description:
      "Lee shares how he learned to implement different types of AI through various personal side projects - static and real-time image analysis, neural networks that learn how to navigate a 2D race track and many others. You don't want to miss this one out!",
    url: "https://www.youtube.com/watch?v=EODnfVNPCiM",
    person: "Lee Englestone",
    year: 2023,
    photo: "lee-englestone.jpg"
  },      
  {
    title: "Practical Introduction to Event Sourcing",
    description:
      "Oskar will demonstrate how to build applications close to business. Take a look at how easy Event Sourcing pattern is and how to get started with it.",
    url: "https://www.youtube.com/watch?v=O3qbCuHvcPk",
    person: "Oskar Dudycz",
    year: 2023,
    photo: "oskar-dudycz.png"
  },
  {
    title: "Lessons learned from migrating to .NET MAUI",
    description:
      "Join us to find out Damian's experiences and lessens learned from migrating a mobile application from Xamarin.Forms to .NET MAUI by multiple teams working in SAFe methodology.",
    url: "https://www.youtube.com/watch?v=Z3MUKIDk6tQ",
    person: "Damian Antonowicz",
    year: 2024,
    photo: "damian-antonowicz.png"
  },
  {
    title: "Beyond the Screen: Navigating Accessibility Tools for Developers",
    description:
      "In this meetup Antonio goes over some of the most useful tools that help us, developers, do a better job when it comes to creating accessible solutions. Tune in to learn more!",
    url: "https://www.youtube.com/watch?v=E2WesIe-vwo",
    person: "Vitor Antonio",
    year: 2024,
    photo: "vitor-antonio.png"
  },
  {
    title: "Performance tests and other lies",
    description:
      "Jarosław will teach us about anti-patterns in performance testing and he'll give us some battle-tested practical advice on how not to be fooled by test results. Sounds interesting? Tune in!",
    url: "https://www.youtube.com/watch?v=l-LepfY6iq0",
    person: "Jarosław Pałka",
    year: 2024,
    photo: "jaroslaw-palka.png"
  },
  {
    title: "Ring, ring! Did your grandpa's phone have a bluetooth?",
    description:
      "An engaging introduction to the remarkable capabilities of modern microcontrollers, taking us beyond the basics. Paweł will demonstrate how to transform an old telephone into a BT-enabled device using the Hands-Free Profile!",
    url: "https://www.youtube.com/watch?v=WuO3PywjBTw",
    person: "Paweł Grudzień",
    year: 2024,
    photo: "pawel-grudzien.png"
  },
  {
    title: "How to create a great CV?",
    description:
      "Join Dawid to find out how to create a professional resume, which will help you in finding new job opprotunities. Dawid will share how this looks like from a recruiter perspective and he'll tell us what makes a CV stand out among multiple others.",
    url: "https://www.youtube.com/watch?v=Zm25rd0965k",
    person: "Dawid Prokopowicz",
    year: 2024,
    photo: "dawid-prokopowicz.png"
  },
  {
    title: "Trawell - my first startup journey",
    description:
      "Listen to Piotr talk about Trawell app - a smart buddy in a traveller's pocket. He will discuss the technology behind it, challanges team faced during the development and their plans for the future. It's a great opportunity to gain insights into Krakow startup environment!",
    url: "https://www.youtube.com/watch?v=Dx7guFK-o-I",
    person: "Piotr Komar",
    year: 2024,
    photo: "piotr-komar.png"
  },
  {
    title: "Personal brand of a programmer",
    description:
      "If you're wondering how to build your personal brand as a programmer, this meetup is for you! Krzysztof will share his experience and tips on how to stand out in the crowd and make your professional profile more attractive to potential employers. Tune in!",
    url: "https://www.youtube.com/watch?v=9ZRpRydPIUg",
    person: "Krzysztof Kempiński",
    year: 2024,
    photo: "krzysztof-kempinski.png"
  }
];

export const pastConferences: IMeetupItem[] = [
  {
    title: "TWF Source Conference 2015",
    description:
      "The theme of this conference is enterprise innovation based on experience. That is, to what extent can current trends in the enterprise be explained by past developments and discoveries? Speakers: Greg Brill, Michał Taszycki, Trevor Longino, Alberto Gemin, and more!",
    url: "https://www.youtube.com/watch?v=SbPZOeEwLDM",
  },
  {
    title: "TWF Conference 2016",
    description:
      "This conference contains speeches about what the near future brings and what we could do for a better tomorrow. Speakers: Gert Franke, Mateusz Dyląg, Mateusz Góra, Jeremy Bibby, John Rabasa, and more!",
    url: "https://www.youtube.com/watch?v=kuSTZU4-Si8",
  },
  {
    title: "TWF Conference 2017",
    description:
      "Once again, a techie crowd of creative thinkers, developers, designers, engineers, entrepreneurs and real geeks filled the auditorium at the Manggha Museum. Speakers: Szymon Pobiega, Paweł Kowaluk, Sara Ramos, Daniel Hirschmann, Paweł Pinio, and more!",
    url: "https://www.youtube.com/watch?v=wWihMHn-cEs",
  },
  {
    title: "TheWayForward Hackathon",
    description:
      "Anna Dymna's Foundation 'Against the Odds' created a home for people with learning disabilities in Radwanowice, near Krakow. The centre is equipped with customly modified computers, which unfortunately lack appropriate software. This is where our hackaton app comes in - created for the greater good.",
    url: "https://www.youtube.com/watch?v=p6kH_rtjdC4",
  },
];
