import React, { Component } from "react";
import "./SpeakerLink.css";

export class SpeakerLink extends Component {
  render() {
    return (
      <div className="speakerLink">
        <a href="https://forms.gle/W8Jt8YVBMWa76yMDA">I want to be a speaker</a>
      </div>
    );
  }
}
