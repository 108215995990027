import React, { Component, PropsWithChildren } from "react";
import { NavMenu } from "./NavMenu";


export class Layout extends Component<PropsWithChildren> {
  static displayName = Layout.name;
  
  render() {
    return (
      <div>
        <NavMenu />
        <>{this.props.children}</>
      </div>
    );
  }
}
