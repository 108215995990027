import React from "react";
import MeetupItem, { IMeetupItem } from "./MeetupItem";
import "./PastMeetups.css";

interface IPastMeetups {
  meetups: IMeetupItem[];
  title: string;
}

const PastMeetups = (props: IPastMeetups) => {
  return (
    <div className="pastMeetups">
      <h2>{props.title}</h2>
      <div className="container">
        {props.meetups.map((item) => (
          <MeetupItem
            title={item.title}
            description={item.description}
            url={item.url}
            person={item.person}
            year={item.year}
            key={item.url}
            photo={item.photo}
          />
        ))}
      </div>
    </div>
  );
};

export default PastMeetups;
