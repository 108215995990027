import React, { Component } from "react";
import "./Home.css";
import { pastMeetups, pastConferences } from "./../data";
import PastMeetups from "./PastMeetups";
import { SpeakerLink } from "./SpeakerLink";

export class Home extends Component {
  render() {
    return (
      <>
        <div className="info hero">
          <div>
            <p>
              The Way Forward community is a platform for software and design
              professionals: developers, designers, programmers, engineers, tech
              nuts and entrepreneurs. We’re about exploring last trends in
              technology and ways to implement those in real (or virtual) life.
            </p>
            <p>
              We’re based in Poland and since we first kicked off in 2014, we’ve
              been organizing annual conferences, workshops and meet-ups focused
              on software design, development and emerging technologies. Over
              the past years, we’ve hosted a number of speaker and lectures from
              around the world.
            </p>
          </div>
          <div className="icons">
            <img
              alt="Facebook"
              onClick={() =>
                window.open("https://www.facebook.com/twf.community")
              }
              src="./facebook.png"
            ></img>
            <img
              alt="YouTube"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCuz1uH7R0PmGbSNf34MegwQ"
                )
              }
              src="./youtube.png"
            ></img>
            <img
              alt="Meetup"
              onClick={() => window.open("https://www.meetup.com/TWF-Meetup/")}
              src="./meetup.png"
            ></img>
              <img
              alt="Linkedin"
              onClick={() => window.open("https://www.linkedin.com/company/the-way-forward-community")}
              src="./linkedin.png"
            ></img>
          </div>
          <SpeakerLink />
        </div>
        <PastMeetups
          title="Check our online meetups:"
          meetups={pastMeetups.reverse()}
        />
        <img alt="mozaic" className="mozaic" src="./mozaic.png" />
        <PastMeetups
          title="Check our past conferences:"
          meetups={pastConferences}
        />
        <div className="speakerInfo hero">
          <div>
            <h2>TWF is looking for YOU!</h2>
            <p>
              Do you love sharing your knowledge with others? Are you familiar
              with AI, Cognitive Services, Web Components, Progressive Web Apps,
              Azure Cloud, .Net Core, Mobile, Performance Optimalization,
              Docker, Web Assembly, IoT, or any other interesting IT topics?
              Even if you have not had the opportunity to be a speaker so far,
              it is never too late!
            </p>
          </div>
          <SpeakerLink />
        </div>
        <p className="footer">
          © {new Date().getFullYear()} by TWF, contact:{" "}
          <a href="mailto:twfmeetup@avanade.com">twfmeetup@avanade.com</a>
        </p>
      </>
    );
  }
}
